import { Add, Remove } from "@mui/icons-material";
import React from "react";
import { sendAnalytics } from "../../utils/analyticsCommon";
import styles from "./faqComponent.module.css";
const FAQComponent = ({
  question,
  answer,
  subPoints = [],
  id,
  view,
  setView,
}) => {
  const handleFaqButtonClick = () => {
    let payload = {
      question_id: id + 1,
      question_index: id + 1,
    };
    sendAnalytics("home_faq_more_detail_button_clicked", payload, "en");
    if (view === id) {
      setView(-1);
    } else {
      setView(id);
    }
  };
  const renderAnswer = () => {
    return (
      <>
        {view === id && (
          <div className={styles.answer}>
            {answer}
            {subPoints?.length > 0 && (
              <ul>
                {subPoints?.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </>
    );
  };
  const renderFaqComponent = () => {
    return (
      <div className={styles.faq} id={id} onClick={handleFaqButtonClick}>
        <div className={styles.questionMain}>
          <div className={styles.question}>{question}</div>
          <div className={styles.arrowFaq}>
            {view !== id ? <Add /> : <Remove />}
          </div>
        </div>
        {renderAnswer()}
        <div className={styles.borderDivider}></div>
      </div>
    );
  };
  return <>{renderFaqComponent()}</>;
};

export default FAQComponent;
