import { Box, Grid, styled, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { imagesList } from "../../constants";
import { theme } from "../../constants/theme";

const CustomOperationalCities = styled(Box)(() => ({
  ".main-box": {
    padding: "50px 20px",
  },
  ".head-text": {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "600",
    margin: "50px auto",
    marginBottom: "20px",
  },

  ".sub-text": {
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "20px",
  },
  ".city-avatar": {
    width: "100px",
    height: "100px",
    cursor: "pointer",
  },
  ".grid-container": {
    display: "flex",
    justifyContent: "center",
  },
  ".grid-item": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  ".city-name-text": {
    fontSize: "14px",
    fontWeight: "400",
  },
  [theme.breakpoints.down("md")]: {
    ".main-box": {
      padding: "25px 20px",
    },
    ".head-text": {
      fontSize: "20px",
      fontWeight: "600",
      margin: "20px auto",
      marginBottom: "10px",
    },

    ".sub-text": {
      fontSize: "14px",
      textAlign: "center",
      marginBottom: "10px",
      //margin: "20px auto",
    },
    ".city-avatar": {
      width: "75px",
      height: "75px",
    },
    ".grid-item": {
      maxWidth: "90px",
      padding: "10px 0px",
    },
    ".city-name-text": {
      fontSize: "10px",
      fontWeight: "400",
    },
  },
}));

const operationalCityInfo = [
  {
    name: "Karnataka",
    path: "/karnataka/electric-auto",
    images: imagesList.karnatakaOperationalCity,
  },
  {
    name: "Delhi",
    path: "/delhi/electric-auto",
    images: imagesList.delhiOperationalCity,
  },
  {
    name: "Telangana",
    path: "/telangana/electric-auto",
    images: imagesList.telenganaOperationalCity,
  },
  {
    name: "Maharashtra",
    path: "/maharashtra/electric-auto",
    images: imagesList.maharashtraOperationalCity,
  },
  {
    name: "Tamil Nadu",
    path: "/tamil-nadu/electric-auto",
    images: imagesList.tamilNaduOperationalCity,
  },
  {
    name: "Gujarat",
    path: "/gujarat/electric-auto",
    images: imagesList.gujratOperationalCity,
  },
];

const OperationalCities = ({ headerInfo }) => {
  const navigate = useRouter();
  return (
    <CustomOperationalCities>
      <Box className="main-box">
        <Box>
          <Typography variant="h1" className="head-text">
            {headerInfo}
          </Typography>
        </Box>

        <Box>
          {" "}
          <Typography className="sub-text">Serving Multiple States</Typography>
        </Box>
        <Box>
          <Grid container className="grid-container">
            {operationalCityInfo.map((res, index) => {
              return (
                <Grid item xs={3} md={1} className="grid-item" key={index}>
                  <LazyLoadImage
                    className="city-avatar"
                    alt={res?.name}
                    src={res?.images}
                    // onClick={() => navigate.push(`${res.path}`)}
                    style={{
                      padding: "4px",
                      borderRadius: "50%",
                      background: "#E0E0E0",
                      border: `2px solid ${"#EFEFEF"}`,
                    }}
                  />
                  {/* <Avatar
                    className="city-avatar"
                    alt="Remy Sharp"
                    src={res.images}
                    
                    imgProps={{ fetchpriority: "low",loading:"lazy"  }}
                    onClick={() => navigate.push(`${res.path}`)}
                  /> */}
                  <Typography className="city-name-text">{res.name}</Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </CustomOperationalCities>
  );
};

export default OperationalCities;
