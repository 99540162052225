import { Box, Rating, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { primary } from "../../constants/theme";
import { MemoizedButton } from "../../SDK/button";
import { sendAnalytics } from "../../utils/analyticsCommon";
import people from "./data";

const CustomReview = styled(Box)(({ theme }) => ({
  display: "grid",
  placeItems: "center",
  fontFamily: theme.fontFamily,

  paddingTop: "40px",
  paddingBottom: "40px",
  background: primary.lightYellowBg,

  ".title": {
    textAlign: "center",
    marginBottom: "4rem",

    fontSize: "36",
    fontWeight: "bold",
  },
  ".underline": {
    height: "0.25rem",
    width: "5rem",

    marginLeft: "auto",
    marginRight: "auto",
  },
  ".container": {
    width: "80vw",
  },
  ".review": {
    padding: "1.5rem 2rem",

    textAlign: "center",
  },

  ".img-container": {
    position: "relative",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    margin: "0 auto",
    marginBottom: "1rem",
  },
  ".person-img": {
    width: "100%",
    display: "block",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
    position: "relative",
  },

  ".author": {
    marginBottom: "0.25rem",
    marginTop: "0.25rem",
    fontSize: "32px",
    fontWeight: "bold",
    color: primary.mainText,
  },
  ".job": {
    marginBottom: "0.5rem",

    fontSize: "22px",
    color: primary.secondary,
    fontWeight: "bold",
  },
  ".info": {
    marginBottom: "0.75rem",
    textAlign: "center",
    fontSize: "22px",
  },

  ".review-star": {
    fontSize: "38px",
    fontWeight: "600",
    display: "flex",
    margin: "20px",
  },

  ".rating": { fontSize: "40px" },

  [theme.breakpoints.down("md")]: {
    padding: "12px",
    margin: "12px",
    borderRadius: "8px",
    ".author": {
      marginBottom: "0.25rem",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "0.25rem",
    },
    ".job": {
      marginBottom: "0.5rem",

      fontSize: "16px",
      color: primary.secondary,
      fontWeight: "bold",
    },
    ".info": {
      marginBottom: "0.75rem",
      textAlign: "center",
      fontSize: "12px",
    },
    ".review-star": {
      fontSize: "22px",
      fontWeight: "600",
      margin: "10px",
    },
    ".rating": { fontSize: "20px" },
  },
}));

const Review = () => {
  const [index, setIndex] = useState(0);
  const { id, name, job, image, text, ratings } = people[index];
  const checkNumber = (number) => {
    if (number > people.length - 1) {
      return 0;
    }
    if (number < 0) {
      return people.length - 1;
    }
    return number;
  };

  // const nextPerson = () => {
  //   setIndex((index) => {
  //     let newIndex = index + 1;
  //     return checkNumber(newIndex);
  //   });
  // };

  // const prevPerson = () => {
  //   setIndex((index) => {
  //     let newIndex = index - 1;
  //     return checkNumber(newIndex);
  //   });
  // };

  const minIndex = 0;
  const maxIndex = people.length - 1;

  const getRandomPerson = () => {
    const getRandomIntInclusive = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
    };

    let randomIndex = getRandomIntInclusive(minIndex, maxIndex);
    if (randomIndex === index) {
      randomIndex = index + 1;
    }
    setIndex(checkNumber(randomIndex));
    let payload = {
      reviewName: name,
      review_id: id,
    };
    sendAnalytics("review_next_clicked", payload, "en");
  };

  return (
    <CustomReview>
      <Typography className="review-star">
        Product Ratings &amp; Reviews
      </Typography>

      <Box className="img-container">
        <LazyLoadImage src={image} alt={name} className="person-img" />
      </Box>
      <Rating className="rating" value={ratings} precision={0.5} />
      <Typography variant="h4" className="author">
        {name}
      </Typography>
      <Typography className="job">{job}</Typography>
      <Typography className="info">{`${text}`}</Typography>

      <MemoizedButton
        content={"Next  >>"}
        animated={true}
        onClick={getRandomPerson}
      />
    </CustomReview>
  );
};

export const MemoizedReview = React.memo(Review);
