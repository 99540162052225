import { Box, List, ListItem, Typography } from "@material-ui/core";
import { Grid, styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { imagesList } from "../../../constants";
import { primary } from "../../../constants/theme";

const CustomerHomePage = styled(Box)(({ theme }) => ({
  position: "relative",
  ".box": {
    paddingLeft: "6em",
    paddingTop: "0em",
    paddingBottom: "3em",
  },
  ".heading": {
    fontWeight: "900",
    fontSize: "4.25em",
    color: primary.main,
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  ".vehicle-group-image": {
    width: "50vw",
  },
  ".box-logos": {
    width: "131px",
    height: "110px",
  },
  ".logos": {
    width: "100%",
  },
  ".logos-guarantee": {
    width: "105%",
  },
  ".Key-points": {
    paddingLeft: "21px",
    lineHeight: "30px",
    fontSize: "20px",
  },
  ".key-points-heading": {
    paddingLeft: "15px",
    fontWeight: "600 !important",
    lineHeight: "21px",
  },
  ".key-points-desc": {
    paddingTop: "8px",
    paddingBottom: "4px",
  },
  [theme.breakpoints.down("md")]: {
    ".box": {
      paddingLeft: "2em",
      paddingTop: "2em",
      paddingBottom: "2em",
    },
    ".heading": {
      fontWeight: "600",
      fontSize: "1.5em",
      paddingTop: "1em",
      color: primary.dark,
      paddingBottom: "1em",
    },
    ".vehicle-group-image": {
      display: "none",
    },
    ".key-points-grid": {
      paddingLeft: "1em !important",
      paddingTop: "1.5em !important",
    },
    ".box-logos": {
      width: "75px",
      height: "75px",
    },
    ".Key-points": {
      paddingLeft: "1em",
      lineHeight: "21px",
      fontSize: "14px",
      fontWeight: "400",
    },
    ".key-points-desc": {
      paddingTop: "6px",
      paddingBottom: "2px",
      paddingLeft: "1em",
      lineHeight: "14px",
    },
  },
}));

const WhyMoveAhead = () => {
  return (
    <CustomerHomePage>
      <Box className="box">
        <Grid container spacing={1}>
          <Grid className="grid" item xs={12} md={5} sx={{ color: "red" }}>
            <Box>
              <Typography className="heading">
                Why move ahead with TURNO?
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box>
              <LazyLoadImage
                className="vehicle-group-image"
                src={imagesList.homeVehicleGroupImages}
                alt="Turno 3 wheeler auto image"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="grid">
          <Grid
            className="key-points-grid"
            item
            xs={12}
            md={4}
            sx={{ padding: "0px !important" }}
          >
            <Box className="box-logos">
              <LazyLoadImage
                className="logos"
                src={imagesList.homeAssurance}
                alt="Turno Assurance"
              />
            </Box>
            <Box className="Key-points">
              {/* <Typography variant="h2" className="key-points-heading">
                Why move ahead with TURNO?
              </Typography> */}
              <List>
                <ListItem className="key-points-desc">
                  <Typography className="text">
                    &#x2022; {"300 point inspection test"}
                  </Typography>
                </ListItem>
                <ListItem className="key-points-desc">
                  <Typography className="text">
                    &#x2022; {"Dedicated buying assistance"}
                  </Typography>{" "}
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid
            className="key-points-grid"
            item
            xs={12}
            md={4}
            sx={{ padding: "0px !important" }}
          >
            <Box className="box-logos">
              <LazyLoadImage
                className="logos"
                src={imagesList.homeCare}
                alt="Turno care"
              />
            </Box>
            <Box className="Key-points">
              <Typography className="key-points-heading">Turno Care</Typography>
              <List>
                <ListItem className="key-points-desc">
                  <Stack>
                    <Typography className="text">
                      &#x2022; {"1.5 Lakh buy back after 3 years"}
                    </Typography>
                  </Stack>
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid
            className="key-points-grid"
            item
            xs={12}
            md={4}
            sx={{ padding: "0px !important" }}
          >
            <Box className="box-logos">
              <LazyLoadImage
                className="logos-guarantee"
                src={imagesList.homeGuarantee}
                alt="Turno money back guarantee"
              />
            </Box>
            <Box className="Key-points">
              <Typography className="key-points-heading">
                Money back guarantee
              </Typography>
              <List>
                <ListItem className="key-points-desc">
                  <Typography className="text">
                    {" "}
                    &#x2022; {"Cancel your booking at any time"}
                  </Typography>
                </ListItem>
                <ListItem className="key-points-desc">
                  <Typography className="text">
                    &#x2022; {"Full refund on booking fee"}
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box></Box>
    </CustomerHomePage>
  );
};

const MemoizedWhyMoveAhead = React.memo(WhyMoveAhead);
export default MemoizedWhyMoveAhead;
