import { imagesList } from "../../constants";

const reviews = [
  {
    id: 1,
    name: "Kamble",
    job: "Fresh Food Distribution",
    image: imagesList.kambleThumbnail,
    text: "Turno provided a great experience when purchasing my first electric load auto. Simple and quick process after test drive",
    ratings: 5,
  },
  {
    id: 2,
    name: "Girish",
    job: "Super Market Delivery",
    image: imagesList.girishCustomer,
    text: "Through Turno i got a chance to test drive both the vehicles, Turno helped me choose the best electric vehicle for my usecase",
    ratings: 5,
  },
  {
    id: 3,
    name: "Vasant Kumar",
    job: "Logistic",
    image: imagesList.deendayalThumbnail,
    text: "My daily expense has reduced with my new load electric gadi. Turno helped me with easy loan with lowest interest rate and guaranteed gadi resale after 3 years.",
    ratings: 4.5,
  },
  // {
  //   id: 4,
  //   name: "Vasant Kumar",
  //   job: "the boss",
  //   image:
  //     "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883423/person-4_t9nxjt.jpg",
  //   text: "Edison bulb put a bird on it humblebrag, marfa pok pok heirloom fashion axe cray stumptown venmo actually seitan. VHS farm-to-table schlitz, edison bulb pop-up 3 wolf moon tote bag street art shabby chic. ",
  // },
];

export default reviews;
