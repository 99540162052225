import { Box, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { HomePageFAQ } from "../../../constants/faq";
import FAQ from "../../../SDK/FAQ/faq";
import { sendAnalytics } from "../../../utils/analyticsCommon";

const CustomHomePageFAQ = styled(Box)(({ theme }) => ({
  scrollBehavior: "smooth",
  margin: "80px 10% 60px 10%",
  [theme.breakpoints.down("md")]: { margin: "25px 20px 40px 20px" },
}));

const HomePageFAQs = ({ headText = "",  faqInfo }) => {
  const [showCount, setShowCount] = useState(1);
  const [showMore, setShowMore] = useState(true);
  const [showLess, setShowLess] = useState(false);

  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        sendAnalytics("scroll_home_page_faq_fold", {}, "en");
      }
    }, options);

    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (targetRef.current) observer.unobserve(targetRef.current);
    };
  }, [targetRef]);

  return (
    <CustomHomePageFAQ>
      <Box id="home-page-faq" ref={targetRef}>
        <FAQ
          faqList={faqInfo ?? HomePageFAQ}
          showItems={showCount}
          headText={headText ? headText : "Frequently Asked Questions on Turno"}
        />
        {/* <Box style={{ display: "flex", justifyContent: "space-between" }}>
          {showMore ? (
            <MemoizedButton
              content={"Show More"}
              sx={{
                marginTop: "20px",
                width: "100%",
                color: primary.secondary,
                background: "white",
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: "0px",
                boxShadow: "none",
                border: "1px solid white",
                borderTop: "1px solid grey",
                paddingLeft: "0px",
                "&:hover": {
                  border: "1px solid white",
                  borderTop: "1px solid grey",
                  color: primary.secondary,
                  background: "white",
                },
                "&:disabled": {
                  border: "1px solid white",
                  borderTop: "1px solid grey",
                  background: "white",
                },
              }}
              handleClick={() => {
                setShowLess(true);
                sendAnalytics("faq_show_more_btn_clicked", {}, "en");
                window.scrollBy({
                  top: 325,
                  left: 0,
                  behavior: "smooth",
                });
                showCount === 6 && setShowMore(false);

                setShowCount(
                  HomePageFAQ.length - showCount >= 5
                    ? showCount + 5
                    : HomePageFAQ.length
                );
              }}
            />
          ) : (
            <></>
          )}

          {showLess ? (
            <MemoizedButton
              content={"Show Less"}
              sx={{
                marginTop: "20px",
                width: "100%",
                color: primary.secondary,
                background: "white",
                display: "flex",
                justifyContent: "flex-end",
                borderRadius: "0px",
                boxShadow: "none",
                border: "1px solid white",
                borderTop: "1px solid grey",
                paddingRight: "0px",
                "&:hover": {
                  border: "1px solid white",
                  borderTop: "1px solid grey",
                  color: primary.secondary,
                  background: "white",
                },
                "&:disabled": {
                  border: "1px solid white",
                  borderTop: "1px solid grey",
                  background: "white",
                },
              }}
              handleClick={() => {
                setShowMore(true);
                setShowLess(false);
                sendAnalytics("faq_show_less_btn_clicked", {}, "en");
                myScroll(
                  0,
                  document.getElementById("home-page-faq")?.offsetTop - 100,
                  "smooth"
                );
                setShowCount(1);
              }}
            />
          ) : (
            <></>
          )}
        </Box> */}
      </Box>
    </CustomHomePageFAQ>
  );
};

export default HomePageFAQs;
