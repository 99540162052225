import { Box, Typography } from "@material-ui/core";
import { Grid, styled } from "@mui/material";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { imagesList } from "../../../constants";
import { primary } from "../../../constants/theme";

const CustomBenefitsHome = styled(Box)(({ theme }) => ({
  padding: "48px 66px",
  ".benefits-grid": {
    alignItems: "center",
  },
  ".benefits-heading": {
    fontWeight: "600",
    fontSize: "40px",
    lineHeight: "120%",
    color: primary.dark,
  },
  ".benefit-points": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  ".benefit-points img": {
    width: "112px",
    height: "112px",
  },
  ".benefit-points .benefit-text": {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "34px",
    color: "#606161",
    maxWidth: "224px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
    ".benefits-heading": {
      fontSize: "28px",
      lineHeight: "34px",
    },
    ".benefit-points": {
      display: "block",
      textAlign: "center",
    },
    ".benefit-points img": {
      width: "74px",
      height: "74px",
    },
    ".benefit-points .benefit-text": {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
}));

const HomeBenefits = () => {
  return (
    <CustomBenefitsHome>
      <Grid container spacing={2} className="benefits-grid">
        <Grid sx={{ display: "block" }} item xs={12} md={3}>
          <Typography variant="h2" className="benefits-heading">
            Benefits of Turno
          </Typography>
        </Grid>
        <Grid sx={{ display: "block" }} item xs={4} md={3}>
          <Box className="benefit-points">
            <LazyLoadImage
              src={imagesList.homeCommercialPoint1}
              alt="Turno Lowest prices"
              effect="opacity"
            />
            <Typography className="benefit-text"> Lowest Prices</Typography>
          </Box>
        </Grid>
        <Grid sx={{ display: "block" }} item xs={4} md={3}>
          <Box className="benefit-points">
            <LazyLoadImage
              src={imagesList.homeCommercialPoint2}
              alt="Turno-best financing offers "
              effect="opacity"
            />
            <Typography className="benefit-text">
              Best financing offers
            </Typography>
          </Box>
        </Grid>
        <Grid sx={{ display: "block" }} item xs={4} md={3}>
          <Box className="benefit-points">
            <LazyLoadImage
              src={imagesList.homeCommercialPoint3}
              alt="Turno-money back guarantee"
              effect="opacity"
            />
            <Typography className="benefit-text">Guaranteed BuyBack</Typography>
          </Box>
        </Grid>
      </Grid>
    </CustomBenefitsHome>
  );
};
const MemoizedHomeBenefits = React.memo(HomeBenefits);
export default MemoizedHomeBenefits;
