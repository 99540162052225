import { Box, styled } from "@mui/material";
import React, { useContext, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { imagesList } from "../../../constants";
import { primary } from "../../../constants/theme";
import { Context } from "../../../context/context";
import { MemoizedButton } from "../../../SDK/button";
import { sendAnalytics } from "../../../utils/analyticsCommon";
import { useMobile } from "../../../utils/findViewport";
import { imagesWEBP } from "../../../constants/webpImages";


const CustomDesktopCarousel = styled(Box)(({ theme }) => ({
  ".slider-box": {
    margin: "0px 0px 00px 0px",
    width: "100%",
    borderRadius: "10px",
  },
  // ".image-gallery-bullets-container": {
  //   position: "absolute",
  //   top: "30px",
  //   left: "40%",
  // },
  ".image-gallery-bullet": {
    backgroundColor: "grey !important",
    boxShadow: "none !important",
  },
  ".image-gallery-bullet.active": {
    backgroundColor: "black !important",
  },
}));

const DesktopCarousel = () => {
  const { showCityPopup } = useContext(Context);
  const [button, setButton] = useState(
    <MemoizedButton
      type="submit"
      content={"View Vehicles"}
      sx={{
        background: "#ffffff",
        position: "absolute",
        top: "60%",
        left: "6%",
        boxShadow: "0px 4px 0px #177138",
        width: "150px",
        boxShadow: "none",
        border: "2px solid #E8345E",
        borderRadius: "4px",
        padding: "12px 8px",
        fontSize: "16px",
        color: primary.main,
        "&:hover": {
          background: "#ffffff",
          border: "2px solid #E8345E",
          color: primary.main,
          boxShadow: "none",
        },
      }}
      handleClick={(e) => {
        sendAnalytics("view_all_vehicles_desktop_carousel_clicked", {}, "en");
        showCityPopup();
      }}
    />
  );
  const images = [
    // {
    //   original: imagesList.desktopCarouselBanner1,
    // },
    // {
    //   original: imagesList.desktopCarouselBanner2,
    // },
    {
      original: imagesList.desktopCarouselBanner4,
    },
  ];

  const mobile = useMobile();

  return (
    <CustomDesktopCarousel>
      <Box className="slider-box">
        <LazyLoadImage
          style={{ width: "100%", minHeight: "560px" }}
          src={imagesWEBP?.homePageBanner2}
          visibleByDefault={true}
          fetchpriority={"high"}
          alt="homePage-banner"
          onClick={() => {
            sendAnalytics(
              "view_all_vehicles_desktop_carousel_clicked",
              {},
              "en"
            );
            showCityPopup();
          }}
        />
        {/* <ReactImageGallery
          autoPlay={false}
          showNav={false}
          showBullets={false}
          style={{ height: "2px" }}
          items={images}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          //loading={"lazy"}
          // slideDuration={4000}
          // swipingTransitionDuration={1000}
          onBeforeSlide={() => {
            setButton(<></>);
          }}
          onSlide={() => {
            setButton(
              <>
                <MemoizedButton
                  type="submit"
                  content={"View Vehicles"}
                  sx={{
                    background: "#ffffff",
                    position: "absolute",
                    top: "62%",
                    left: "6%",
                    boxShadow: "0px 4px 0px #177138",
                    width: "150px",
                    boxShadow: "none",
                    border: "2px solid #E8345E",
                    borderRadius: "4px",
                    padding: "12px 8px",
                    fontSize: "16px",
                    color: primary.main,
                    "&:hover": {
                      background: "#ffffff",
                      border: "2px solid #E8345E",
                      color: primary.main,
                      boxShadow: "none",
                    },
                  }}
                  handleClick={(e) => {
                    sendAnalytics(
                      "view_all_vehicles_desktop_carousel_clicked",
                      {},
                      "en"
                    );
                    showCityPopup();
                  }}
                />
              </>
            );
          }}
        /> */}
        {/* {button} */}
      </Box>
    </CustomDesktopCarousel>
  );
};

export default DesktopCarousel;
