import { Typography } from "@material-ui/core";
import { Box, styled } from "@mui/material";
import React, { useState } from "react";

import { primary } from "../../constants/theme";
import FAQComponent from "./faqComponent";

const CustomFAQ = styled(Box)(({ theme }) => ({
  ".heading-info": {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "78px",
    color: primary.dark,
    marginBottom: "16px",
    fontFamily: '"Poppins", sans-serif',
  },
  [theme.breakpoints.down("md")]: {
    ".heading-info": {
      fontSize: "24px",
      lineHeight: "30px",
      marginBottom: "24px",
    },
  },
}));
const FAQ = ({ faqList, showItems, headText }) => {
  const [view, setView] = useState(0);

  return (
    <CustomFAQ>
      <Box>
        <Box>
          <Typography variant="h2" className="heading-info">
            {headText}
          </Typography>{" "}
        </Box>
        {faqList?.map((faq, id) => {
          // if (id < showItems) {
          return (
            <FAQComponent
              showItems={showItems}
              FAQCounts={faqList.length}
              key={id}
              question={faq.question}
              answer={faq.answer}
              subPoints={faq?.subPoints}
              id={id}
              view={view}
              setView={setView}
            />
          );
          // } else {
          //   return (
          //     <Box key={id} style={{ display: "none" }}>
          //       <FAQComponent
          //         showItems={showItems}
          //         FAQCounts={faqList.length}
          //         key={id}
          //         question={faq.question}
          //         answer={faq.answer}
          //         id={id}
          //         view={view}
          //         setView={setView}
          //       />
          //     </Box>
          //   );
          // }
        })}
      </Box>
    </CustomFAQ>
  );
};

export default FAQ;
